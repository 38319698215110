h1,
.h1 {
	font-size: rem(28);
	font-weight: 100;
	margin: 0 0 rem(7);
	@include breakpoint($large) {
		font-size: rem(50);
	}
}

h2,
.h2 {
	font-size: rem(24);
	line-height: rem(32);
	font-weight: 100;
	margin: 0 0 rem(7);
	@include breakpoint($large) {
		font-size: rem(32);
		line-height: rem(42);
	}
}

h3,
.h3 {
	font-size: rem(18);
	font-weight: 300;
	margin: 0 0 rem(7);
	line-height: 1.2;
	@include breakpoint($large) {
		font-size: rem(24);
	}
}

h4,
.h4 {
	font-size: rem(16);
	font-weight: 400;
	margin: 0 0 rem(7);
	@include breakpoint($large) {
		font-size: rem(16);
	}
}

h5,
.h5 {
	font-size: rem(12);
	font-weight: 500;
	margin: 0 0 rem(7);
	@include breakpoint($large) {
		font-size: rem(12);
	}
}

p,
p a {
	font-size: rem(16);
	font-weight: 300;
	margin: 0 0 rem(10);
	line-height: 1.5;

	@include breakpoint($large) {
		font-size: rem(18);
	}
}

a {
	color: $cta-blue;
	&.link-external {
		font-size: rem(12);
		padding-right: 15px;
		background-image: url("../../assets/img/external-link.svg");
		background-size: 10px 10px;
		background-position: right;
		background-repeat: no-repeat;
	}
}
