.personalized {
  &__title {
    font-size: rem(14);
    font-weight: 400;
    line-height: rem(24);
    margin-bottom: rem(8);
  }
  &__list {
    @include reset-list;

    margin-bottom: rem(24);

    @include breakpoint($medium) {
      margin-bottom: rem(32);
    }
  }
  &__item {
    align-items: center;
    display: flex;
    font-size: rem(14);
    line-height: rem(24);
    margin-bottom: rem(8);

    &:last-child {
      margin-bottom: 0;
    }

    img {
      margin-right: rem(12);
      width: rem(16);

      @include breakpoint($medium) {
        width: rem(24);
      }
    }
  }
  &__login {
    color: $xfinity-blue;
    font-weight: bold;
    font-size: rem(14);
    font-weight: 400;
    line-height: rem(24);
    margin: 0;
    max-width: rem(270);

    @include breakpoint($small) {
      max-width: unset;
    }
  }
}