%btn {
	cursor: pointer;
	-webkit-appearance: none;
	border: 0;
	display: inline-block;
	text-decoration: none;
}

.button--container {
	text-align: center;
	font-size: rem(12);
	font-weight: 500;
	button {
		&.button {
			min-width: rem(210);
			&--link {
				margin: 0;
			}
		}
	}
}

input,
button {
	font-family: XfinityStandard, Helvetica, Arial, sans-serif;
}

.button {
	@extend %btn;
	color: white;
	fill: white;
	padding: rem(17) rem(32) rem(13);
	margin: 0 auto;
	background: $xfinity-black;
	text-align: center;
	font-size: rem(14);
	line-height: 1;
	font-weight: 700;
	width: auto;
	min-width: 0;
	max-width: 100%;
	border-radius: rem(8);
	transition: background-color 200ms, color 200ms, opacity 200ms;
	border: 2px solid transparent;
	font-family: XfinityBrownWeb;
	letter-spacing: -0.2px;

	&:hover,
	&:focus {
		background-color: $white;
		outline: none;
		color: $black;
		fill: white;
		text-decoration: none;
		border: 2px solid $xfinity-border;
	}

	&:hover {
		background-color: $white;
	}

	&:focus {
		background-color: $alto;
	}

	&:disabled {
		background-color: transparent;
		border: 1px solid $alto;
		color: $alto;
	}
	&__secondary {
		background-color: $white;
		border: 2px solid $xfinity-border;
		color: $black;
		&:hover,
		&:focus {
			background-color: $black;
			border-color: $black;
			color: $white;
		}
	}

	&__tertiary {
		background-color: transparent;
		outline: none;
		text-decoration: none;
		font-weight: 700;
		color: $xfinity-black;
		margin: 0;
		border: 0;
		padding: 0;
		cursor: pointer;
		font-family: XfinityBrownWeb;
		font-size: 14px;

		span {
			border-bottom: 2px solid $xfinity-border;
			padding: 0 0 rem(4);
		}

		&:hover,
		&:focus {
			color: darken(desaturate($xfinity-black, 1.5), 4);
		}

		&:hover {
			span {
				border-bottom: 4px solid $black;
				font-weight: 700;
				padding: 0 0 rem(2);
			}
		}

		&:focus {
			span {
				border-color: $black;
			}
		}
	}

	&--link {
		padding: 0;
		font-size: rem(12);
		font-weight: 500;
		min-width: 0;
		margin: 0 rem(10);
		cursor: pointer;

		&,
		&:hover {
			color: $cta-blue;
			background-color: transparent;
			border: 0;
		}

		&--large {
			padding: 0;
			font-size: rem(14);
			font-weight: 500;
			text-transform: uppercase;
			min-width: 0;
			cursor: pointer;

			&,
			&:hover {
				color: $cta-blue;
				background-color: transparent;
				border: 0;
			}
		}

		&--underline {
			padding: 0;
			font-size: rem(12);
			font-weight: 500;
			min-width: 0;
			margin: 0 rem(10);
			cursor: pointer;
			text-decoration: underline;

			&,
			&:hover {
				color: $cta-blue;
				background-color: transparent;
				border: 0;
			}
		}
	}

	&.button--wide {
		@include breakpoint($tiny) {
			min-width: rem(260);
		}
	}
	&.button--hollow {
		background: transparent;
		color: $prepaid-text;
		border: 1px solid $cta-blue;
		&-white {
			display: block;
			background: transparent;
			color: $white;
			border: 1px solid $white;
			&:hover {
				background: darken(desaturate($tundora, 1.5), 4);
			}
		}
	}
	&.button--inline {
		margin-right: rem(10);
	}
}

button.form-submit-button,
input[type="button"].form-submit-button,
input[type="submit"].form-submit-button,
.button.form-submit-button {
	display: block;
	margin: rem(30) auto 0;
	max-width: 100%;

	@include breakpoint($tiny) {
		min-width: rem(260);
	}
	@include breakpoint($medium) {
		margin-left: 0;
		margin-top: rem(40);
	}
}

a.form-submit-button.form-submit-button {
	display: inline-block;
}
