.checkbox-toggle {
  margin-bottom: rem(24);
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &__input {
    opacity: 0;
    margin-bottom: 0;
    width: 0;
    margin: 0;
    position: absolute;
    
    & + label {
      display: flex;
      align-items: center;
      padding-left: rem(81);
      position: relative;
      line-height: 1.25;
      min-height: rem(35);
      transition: all .2s ease-in-out;
      cursor: pointer;

      // background placed ontop of container
      &::before {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #8b8b97;
        height: 35px;
        width: 61px;
      }

      // white box that slides left/right
      &::after {
        top: 50%;
        transform: translateY(-50%);
        left: 4px;
        background: #fff;
        height: 27px;
        width: 27px;
      }

      // shared styles for background and white box
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        transition: all .2s ease-in-out;
      }

      // container
      .checkbox-toggle__switch {
        color: #fff;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        height: 35px;
        width: 61px;
        font-size: rem(12);
        line-height: rem(12);
        font-weight: 700;

        // Yes/No text. Placed on either side of container
        &::before,
        &::after {
          position: absolute;
          top: 50%;
          transition: all .2s ease-in-out;
        }
        
        &::before {
          content: 'Yes';
          position: absolute;
          left: 6px;
          transform: translate(60%, -50%);
          opacity: 0;

          @at-root .calculator-wrap--es & {
            content: 'Sí';
            left: 10px;
          }
        }
        
        &::after {
          content: 'No';
          position: absolute;
          right: 7px;
          transform: translate(0, -50%);
          opacity: 1;
        }
      }
    }

    &:checked + label {
      // background
      &::before {
        background: $xfinity-purple;
      }
      // white box
      &::after {
        left: 30px;
      }

      // container
      .checkbox-toggle__switch {
        // Yes/No text
        &::before {
          transform: translate(0, -50%);
          opacity: 1;
        }
        &::after {
          transform: translate(-60%, -50%);
          opacity: 0;
        }
      }
    }

    &:focus + label::before {
      box-shadow: 0px 0px 5px 1px $focus-color;
      outline: none;
    }
  }
}