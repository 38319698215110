.calculator-header {
	padding: rem(18) rem(30);
	background-color: $white;
	@include breakpoint($xlarge) {
		padding: rem(15) 0;
	}
	&__wrapper {
		max-width: 1160px;
		margin: auto;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__logo {
		margin-bottom: -9px;
		&:focus {
			outline-color: $focus-color;
		}
	}
	&__locale {
		color: #989898;
		a {
			font-size: rem(14);
			line-height: rem(18);
			color: $black;
			text-decoration: none;
			padding: rem(10) rem(5);
			&:focus {
				outline-color: $focus-color;
			}

			@include breakpoint($medium) {
				font-size: rem(12);
			}
		}

		.long {
			display: none;
		}

		.pipe {
			position: relative;
			top: -2px;
		}

		.english-link {
			color: #989898;
		}

		@include breakpoint($medium) {
			.long {
				display: inline-block;
			}
			.pipe,
			.short {
				display: none;
			}

			.english-link {
				display: none;
			}
		}

		@at-root .calculator-wrap--es & {
			.english-link {
				color: $black;
			}

			.spanish-link {
				color: #989898;
			}
			
			@include breakpoint($medium) {
				.spanish-link {
					display: none;
				}
				.english-link {
					display: inline;
				}
			}
		}
	}
}
