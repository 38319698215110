.range-fancy {
	$thumb-size: 20px;
	&__input {
		background: linear-gradient(to right, $xfinity-purple 0%, $xfinity-purple 50%, $xfinity-lt-gray 50%, $xfinity-lt-gray 100%);
		height: 8px;
		outline: none;
		outline-offset: 7px;
		padding: 0 41px;
		width: 100%;
		-webkit-appearance: none;


		@include breakpoint($medium) {
			padding: 0 119px;
		}

		&::-webkit-slider-thumb {
			width: $thumb-size;
			border-radius: $thumb-size;
			-webkit-appearance: none;
			height: $thumb-size;
			background: $white;
			border: 1px solid;
			border-color: rgba(0, 0, 0, .1);
			transition: all 0.35s ease;

		}
		&::-webkit-slider-thumb:active, 
		&:hover::-webkit-slider-thumb,
		&:focus-visible::-webkit-slider-thumb{
			border-color: $xfinity-black;
		}

		&:focus-visible {
			outline: 2px solid $focus-color;
		}
	}
	
	&__sub-label {
		color: rgba(13, 13, 15, 0.57);
		display: flex;
		font-size: rem(14);
		font-weight: 700;
		justify-content: space-between;
		line-height: 18px;
		padding: 0 41px;
		

		@include breakpoint($medium) {
			padding: 0 119px;
		}

		&--left {
			position: relative;
			left: -5px;
		}

		&--right {
			position: relative;
			right: -9px;
		}

		&--active {
			color: $xfinity-black;
		}
	}
}