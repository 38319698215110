.modal {

  &__body {

    &--open {
      overflow: hidden;
    }
  }

  &__overlay {
    background-color: rgba(0, 0, 0, .4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem(15);
    overflow-y: scroll;
  }

  &__content {
    font-family: XfinityBrownWeb, Helvetica, Arial, sans-serif;
    font-size: rem(16);
    font-weight: 400;
    color: $disclaimer-grey;
    position: relative;
    background: #fff;
    border-radius: 8px;
    outline: none;
    padding: rem(72) rem(30) rem(25);
    box-shadow: 0 rem(11) rem(15) rem(-7) rgba(0, 0, 0, 0.2),
      0 rem(24) rem(38) rem(3) rgba(0, 0, 0, 0.14),
      0 rem(9) rem(46) rem(8) rgba(0, 0, 0, 0.12);
    max-width: rem(900);
    flex: 1;
    word-break: break-word;
    margin: auto;

    h2 {
      color: #0D0D0F;
      font-size: rem(26);
      font-weight: 700;
      line-height: rem(34);
      margin-bottom: rem(24);
    }

    p {
      color: #303030;
      font-weight: 400;

      &.disclaimer {
        font-size: rem(14);
        line-height: rem(20);
      }
    }

    a {
      color: #1F69FF;
      font-weight: 700;
    }

    @include breakpoint($medium) {
      padding: rem(80) rem(48);

      h2 {
        font-size: rem(32);
        line-height: rem(40);
      }

      a {
        font-size: rem(16);
        line-height: rem(24);
      }

      p {
        font-size: rem(16);
        line-height: rem(24);
      }
    }
  }

  &__close-button {
    position: absolute;
    right: rem(32);
    top: rem(32);
    border: 0;
    background: $white;
    width: rem(24);
    height: rem(24);
    padding: 0;
    cursor: pointer;
  }
}