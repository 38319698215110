@mixin code {
	display: inline;
	padding: 1px 3px;
	background: #444;
	color: #fff;
}

@mixin type-numeric {
	font-family: XfinityBrownWeb;
	font-size: rem(120);
	font-weight: 200;
	@include breakpoint($medium) {
		font-size: rem(64);
	}
}

@mixin type-label {
	font-size: rem(11);
	line-height: rem(16);
	font-weight: 600;
}

@mixin type-heading-1 {
	font-family: XfinityBrownWeb;
	font-size: rem(26);
	line-height: rem(36);
	font-wight: 100;
	@include breakpoint($large) {
		font-size: rem(40);
		line-height: rem(54);
	}
}

@mixin type-heading-2 {
	font-size: rem(18);
	line-height: rem(24);
	font-weight: 300;
	@include breakpoint($large) {
		font-size: rem(22);
		line-height: rem(32);
	}
}

@mixin type-small-title {
	font-size: rem(13);
	line-height: rem(16);
	letter-spacing: 1px;
	text-transform: uppercase;
	font-weight: 600;
}

@mixin type-subheading {
	font-size: rem(16);
	line-height: rem(24);
	font-weight: 400;
}

@mixin type-display-1 {
	font-size: rem(26);
	line-height: rem(36);
	font-weight: 200;
}

@mixin max-section-width {
	margin-left: auto;
	margin-right: auto;
	max-width: 768px;
}