
@keyframes menuFadeIn {
    0% {
        opacity: 0;
        transform: translateY(-2rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.calculator {
    .react-select {
        &__control {
            background: $xfinity-lt-gray;
            border: solid $xfinity-black;
            border-width: 0 0 2px;
            color: $black;
            font-family: XfinityBrownWeb;
            font-size: rem(18);
            font-style: italic;
            line-height: initial;
            min-height: rem(18);
            padding: rem(7) rem(10) rem(5) rem(12);

            &:hover {
                border-color: $black;
            }

            &--menu-is-open {
                .react-select__indicator::after {
                    transform: translateY(-50%) rotate(225deg);
                }
            }
        }

        &__value-container {
            padding: 0;
        }

        &__indicator-separator {
            background-color: transparent;
        }

        &__indicator {
            position: relative;

            svg {
                visibility: hidden;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 15px;
                transition: all 0.4s;
                transform: translateY(-90%) rotate(45deg);
                width: 10px;
                height: 10px;
                border-right: 1px solid #303030;
                border-bottom: 1px solid #303030;
                pointer-events: none;
            }
        }

        &__menu {
            animation: menuFadeIn 0.4s ease-in-out;
            font-size: rem(16);
            margin: rem(6) 0;

            &::after {
                background: linear-gradient(
                                0deg,
                                rgba(255, 255, 255, 1) 0%,
                                rgba(255, 255, 255, 0.5) 100%
                );
                bottom: 0;
                content: '';
                display: block;
                left: 0;
                height: rem(36);
                pointer-events: none;
                position: absolute;
                width: 100%;
            }
        }

        &__menu-list {
            max-height: 22.5em;
            padding: rem(10);
        }

        &__option {
            border-radius: 4px;
            padding: rem(12) rem(10) rem(8);

            &--is-selected {
                background-color: initial;
                color: $black;
                font-weight: 700;
            }

            &--is-focused {
                background-color: $xfinity-lt-gray;
            }
        }
    }
}