.calculator-footer {
	background-color: $black;

	&__disclaimer {
		font-size: rem(11);
		line-height: rem(16);
		font-weight: 400;
		color: transparentize($white, 0.2);
		text-align: center;
		max-width: 600px;
		margin: auto;
		padding: 0 rem(30) rem(20);
	}
	&__shipping {
		background-color: $shipping-bg;
		padding: rem(25);
		@include type-subheading();
		text-align: center;
	}
	&__nav {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		list-style: none;
		padding: rem(38) rem(30);
		margin: 0;
		@include breakpoint($medium) {
			flex-direction: row;
			padding: rem(50) rem(40) rem(40);
		}
		&--item {
			a {
				position: relative;
				display: block;
				@include type-small-title();
				color: transparentize($white, 0.2);
				text-decoration: none;
				padding: rem(10);
				text-align: center;
				font-weight: 400;
				&:focus {
					outline-color: $focus-color;
				}
			}
			&.ad-choice {
				a {
					&:after {
						content: "";
						background-image: url("../../assets/img/adchoices.svg");
						background-size: 10px 10px;
						background-position: center;
						width: 10px;
						height: 10px;
						position: absolute;
						right: -5px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
}
