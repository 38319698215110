.calculator {
	&__page-heading {
		&--savings {
			padding: rem(48) rem(30) rem(25);
			text-align: center;

			@include breakpoint($medium) {
				padding: rem(80) rem(64) rem(32);
			}

			.calculator__secondary-heading {
				@include breakpoint($medium) {
					font-size: rem(48);
					line-height: 1.15;
				}
			}
		}

		&--savings-alt {
			padding: rem(48) rem(30) 0;
			text-align: center;

			@include breakpoint($medium) {
				padding: rem(80) rem(64) 0;
			}

			.calculator__secondary-heading {
				font-size: rem(26);
				letter-spacing: 0.25px;
				line-height: 1.31;

				@include breakpoint($medium) {
					font-size: rem(36);
					letter-spacing: 0;
					line-height: 1.22;
				}
			}

			.calculator__subtitle {
				padding-top: rem(16);
				letter-spacing: 0;

				@include breakpoint($medium) {
					line-height: 1.25;
					padding-top: rem(25);
				}
			}
			.calculator__button-wrap {
				a.button {
					margin: 0 rem(10) rem(20);

					@include breakpoint($medium) {
						margin: 0 rem(16);
					}
				}
			}
		}
	}
	&__banner {
		background-color: $xfinity-purple;
		color: $white;
		margin-bottom: rem(4);

		@include breakpoint($xlarge) {
			position: relative;
		}
		&--image {
			@include breakpoint($xlarge) {
				aspect-ratio: 1440/282;
			}
		}
	}
	&__banner-media {
		@include breakpoint($xlarge) {
			position: relative;
			z-index: 1;
		}

		img {
			display: none;

			@include breakpoint($xlarge) {
				aspect-ratio: 1440/282;
				display: block;
				height: auto;
				width: 100%;
			}
		}
	}
	&__banner-badge {
		width: rem(150);
		display: block;
		margin: 0;

		@include breakpoint($xlarge) {
			width: rem(176);
			margin: 0 rem(25) 0 0;
		}

		img {
			max-width: 100%;
		}
	}
	&__banner-content {
		padding: rem(28) rem(30);

		@include breakpoint($xlarge) {			
			padding: rem(40) rem(140);						
		}
		.calculator__banner--image & {
			padding: rem(32) rem(30);
			@include breakpoint($xlarge) {
				align-items: center;
				bottom: 0;
				display: flex;
				left: 0;
				padding: 0 rem(30);
				position: absolute;
				right: 0;
				top: 0;
				z-index: 2;
			}
		}
	}
	&__banner-content-inner {
		align-items: center;
		display: flex;
		flex-direction: column;
		width: 100%;

		@include breakpoint($xlarge) {
			margin: auto;
			max-width: 1160px;
			flex-direction: row;
		}

		.calculator__secondary-heading {
			font-size: rem(32);
			line-height: rem(40);

			@include breakpoint($large) {
				font-size: rem(40);
				letter-spacing: 0.25px;
				line-height: rem(50);
			}
		}
	}
	&__banner-text {
		text-align: center;
		@include breakpoint($xlarge) {
				text-align: left;
		}
		.calculator__banner--image & {
			@include breakpoint($xlarge) {
				max-width: rem(660);
			}
		}
		.calculator__banner--image & {
			&--long {

				@include breakpoint($xlarge) {
					max-width: rem(720);
				}
			}
		}
		
	}
	&__main-heading {
		font-family: XfinityBrownWeb;
		font-size: rem(18);
		font-weight: 700;
		letter-spacing: 0;
		line-height: rem(26);
		margin: 0 0 rem(16);
		padding: 0;
		text-transform: none;

		@include breakpoint($medium) {
			font-size: rem(20);
			letter-spacing: -1.5px;
			line-height: rem(28);
			margin-bottom: rem(8);
		}
	}
	&__secondary-heading {
		font-family: XfinityBrownWeb;
		font-size: rem(32);
		font-weight: 700;
		line-height: rem(40);
		margin: 0;
		padding: 0;
	}
	&__tertiary-heading {
		font-family: XfinityBrownWeb;
		font-size: rem(20);
		font-weight: 700;
		line-height: rem(26);
		color: $xfinity-blue;
		margin: 0 auto;
		max-width: rem(1160);
		letter-spacing: -1.5px;
		text-align: center;

		@include breakpoint($xlarge) {
			text-align: left;
		}
	}
	&__tertiary-heading-container {
		padding: 0 rem(40);
		margin: 0 0 rem(30);

		@include breakpoint($xlarge) {
			margin: rem(-10) 0 rem(36);
		}
	}
	&__subtitle {
		font-size: rem(16);
		line-height: 1.5;
		font-weight: 400;
		display: block;
		padding-top: rem(10);
		margin: 0;
		@include breakpoint($medium) {
			font-size: rem(18);
			line-height: 1.44;
		}
	}
	&__button-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
        flex-direction: column;
        &.alt {
            @include breakpoint($medium) {
                flex-direction: row;
            }
        } 
		.button {
			margin: rem(64) rem(10) 0;
			width: auto;
			@include breakpoint($medium) {
				width: auto;
				margin: rem(68) rem(16) 0;
			}
			&__tertiary {
				margin-bottom: 0;

				@include breakpoint($medium) {
					margin: 0 rem(16) 0;
				}
			}
		}
		&--bg-grey {
			background-color: transparent;
			padding: rem(24) 0;
			border-radius: 0;
			border-bottom: 1px solid $xfinity-border;

			@include breakpoint($medium) {
				padding: rem(32) 0;
			}
		}

		.flex-wrapper {
			justify-content: center;
			flex-direction: column;

			@include breakpoint($medium) {
				flex-direction: row;
			}
		}
	}
	&__progress-bar {
		display: flex;
		align-items: flex-end;
		justify-content: space-evenly;
		border-bottom: 2px solid #ececf3;
		position: relative;
		margin: 0 rem(30) rem(24);
		@include max-section-width();

		&:after {
			content: "";
			position: absolute;
			bottom: -2px;
			left: 0;
			width: calc(100% / 3);
			height: 5px;
			background-color: $xfinity-blue;
			transition: all 0.35s ease;
		}
		&.sixty-six {
			&:after {
				width: calc((100% / 3) * 2);
			}
		}
		&.one-hundred {
			&:after {
				width: 100%;
			}
		}
	}
	&__progress-button {
		font-family: XfinityBrownWeb;
		background-color: $white;
		border: none;
		font-size: rem(14);
		line-height: rem(18);
		padding: rem(10) 0 rem(15);
		font-weight: 700;
		letter-spacing: -0.5px;
		color: transparentize($xfinity-black, 0.4);
		width: calc(100% / 3);
		position: relative;
		border-top: 5px solid transparent;
		transition: border 0.35s ease;

		@include breakpoint($medium) {
			font-size: rem(16);
			letter-spacing: 0;
			line-height: 1.12;
		}

		&:after {
			content: "";
			width: 4px;
			height: 7px;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			background-image: url('../../assets/img/arrow-icon-green.svg');
			background-size: contain;
			background-repeat: no-repeat;
			transition: all 0.35s ease;
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
		&.active-progress-button {
			color: $black;
			font-weight: 700;
			&:focus {
				outline: none;
				border-top: 5px solid $xfinity-blue;
			}
		}
		&.arrow-back {
			&:after {
				transform: translateY(-50%) rotate(180deg);
			}
		}
		&:hover {
			cursor: pointer;
		}
	}
	&__form {		
		font-family: XfinityBrownWeb;
		padding: 0 rem(30) rem(64);
		@include breakpoint($medium) {
			padding: 0 0 rem(68); 
		}
		input,
		select {
			&:focus {
				outline-color: $focus-color;
			}
		}
		input[type="number"]::-webkit-inner-spin-button,
		input[type="number"]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		input[type="number"] {
			-moz-appearance: textfield;
		}
		&--label {
			color: $xfinity-black;
			font-size: rem(18);
			line-height: rem(26);
			font-weight: 700;
			text-align: center;
			padding: rem(64) 0 rem(36);
			display: block;
			@include breakpoint($medium) {
				font-size: rem(20);
				line-height: rem(28);
				letter-spacing: -1.5px;
				margin: 0;
				padding: rem(68) 0 rem(48); 
			}
			&.monthly-bill_label {
				margin: auto;
				text-align: center;
			}
			.calculator__lines & {
				max-width: rem(360);
				margin: auto;
			}
		}
		&--legend {
			color: $xfinity-black;
			text-align: center;
			font-size: rem(14); 
			font-weight: 300;
			line-height: rem(16);
			padding: rem(24) 0;
			@include breakpoint($medium) {
				padding: rem(48) 0 rem(24);
			}
		}

		&--input-wrap {					
			@include breakpoint($medium) {
				margin: auto;
				max-width: 760px;
			}
			&.monthly-bill_input-wrap {
				@include breakpoint($medium) {
					display: flex;
				}
			}
		}
		&--dollar-sign {
			font-size: rem(18);
			padding-right: rem(6);
			font-weight: 700;
			color: $mine-shaft;
		}

		&--symbol-input-wrap {
			display: flex;
			width: 100%;
			margin: auto;
			background: $xfinity-lt-gray;
			border: solid $black;
			border-width: 0 0 2px;
			border-radius: 4px;
			padding: rem(12) rem(12) rem(10);

			input {
				background: transparent;
				border: 0;
				color: transparentize($xfinity-black, 0.5);
				font-family: XfinityBrownWeb;
				font-size: rem(18);
				font-style: italic;
				line-height: initial;
				padding: 0;
			}
		}

		&--input {
			border: none;
			border-bottom: 1px solid $input-border;
			display: block;
			width: 100%;
			font-size: rem(40);
			padding: rem(10) rem(10) 0;
			&::placeholder {
				color: $mine-shaft;
				opacity: 0.3;
				font-weight: 600;
			}
		}
	}
	&__lines {
		@include max-section-width();
	}
	&__lines-edit {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 0 rem(28);
		max-width: 245px;
		margin: auto;

		@include breakpoint($medium) {
			max-width: 265px;
			padding-bottom: rem(44);
		}
	}
	&__lines-number {
		width: calc(100% - 100px);
		text-align: center;
		border: none;
		background: none;
		@include type-numeric();
		padding: 0;
		bottom: -0.1em;
		font-family: XfinityBrownWeb;
		font-weight: 600;
		position: relative;
	}
	&__lines-disclaimer {
		font-family: XfinityBrownWeb;
		font-size: rem(14);
		line-height: rem(18);
		font-weight: 400;
		color: $black;
		text-align: center;
		max-width: rem(360);
		margin: auto;
		padding: 0;
	}
	&__lines-btn {
		width: 60px;
		height: 60px;
		min-width: 60px;
		min-height: 60px;
		border: 2px solid $xfinity-border;
		border-radius: 100%;
		background-color: $white;
		font-weight: 400;
		line-height: 0;
		padding: 0;
		font-size: rem(48);
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.35s ease;

		@include breakpoint($medium) {
			font-size: rem(28);
			height: 40px;
			left: 1px;
			min-height: 40px;
			min-width: 40px;
			position: relative;
			width: 40px;
		}

		span {
			transform: translateY(-5px);

			@include breakpoint($medium) {
				transform: translateY(-4px);
			}
		}
		&:hover {
			cursor: pointer;
			border-color: $black;
			background-color: $white;
		}
		&:focus {
			background-color: $lines-hover;
			color: $black;
			border-color: $lines-hover;
			outline: none;
		}
		&:disabled {
			border-color: $xfinity-border;
			color: $xfinity-border;
		}
	}
	&__lines-wrap {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin: rem(16) 0 0;

		@include breakpoint($medium) {
			margin: rem(-24) 0 0;
		}

		&--item {
			width: 100%;
			@include breakpoint($medium) {
				width: calc(50% - 20px);
				&:nth-child(even) {
					margin-left: 20px;
				}
				&:nth-child(odd) {
					margin-right: 20px;
				}
			}
		}
	}
	&__select-label {
		text-transform: none;
		color: $xfinity-black;
		padding: 0;
		display: block;
		font-size: rem(14);
		font-weight: 400;
		line-height: rem(20);
		margin: 0 0 rem(8);
		margin-top: rem(28);
		@include breakpoint($medium) {
			margin-top: 0;
		}
	}
	&__select-wrap {
		position: relative;
		&.select-wrap__monthly-provider{
			width: 100%;
		}
	}
	&__data-select {
		border: solid $black;
		border-width: 0 0 2px;
		border-radius: 6px;
		padding: rem(15) rem(40) rem(14) rem(12);
		display: block;
		width: 100%;
		font-size: rem(18);
		line-height: initial;
		color: $black;
		font-family: XfinityBrownWeb;
		font-weight: 500;
		-webkit-appearance: none;
		background: $xfinity-lt-gray;

		&::after {
			top: 50%;
			transform: translateY(-100%) rotate(45deg);
		}
	}
	&__data-usage {
		@include max-section-width();
	}
	&__bill-disclaimer {
		font-size: rem(12);
		line-height: rem(20);
		font-weight: 400;
		padding: 0 0 rem(8);
		display: block;
		@include breakpoint($medium) {
			margin: auto;
			max-width: none;
		}
	}
	
	&__monthly-fee {
		@include breakpoint($medium) {
			flex-basis: 50%;
		}
	}

	&__monthly-provider {
		width: 100%;
		margin: 0 auto;

		@include breakpoint($medium) {
			flex-basis: 50%;
			margin-left: rem(40);
		}
	}

	&__show-savings {
		background-color: transparent;
		border-radius: 6px;
		margin: 0 rem(30);
		padding: 0;
		@include breakpoint($medium) {
			max-width: 560px;
			margin: 0 auto;
		}

		&--is-loading {
			.calculator__savings-banner,
			.calculator__recommended-plan {
				position: relative;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: block;
					animation-duration: 1.5s;
					animation-fill-mode: forwards;
					animation-iteration-count: infinite;
					animation-timing-function: linear;
					animation-name: placeHolderShimmer;
					background: #eeeeee;
					background: linear-gradient(to right, #ececf3 1%, #dfdfe5 50%, #ececf3 100%);
					background-size: 560px 100px;

					@keyframes placeHolderShimmer {
						0%{ background-position: -560px 0; }
						100%{ background-position: 560px 0; }
					}
				}

				& > * {
					opacity: 0;
					pointer-events: none;
				}
			}
		}

		.calculator__button-wrap {
			.button {
				&__primary,
				&__secondary {
					margin: 0 rem(10) rem(20);
					
					@include breakpoint($medium) {
						margin: 0 rem(16);
					}
				}
			}
		}
	}
	&__savings-banner {
		background: #ececf3;
		border-radius: 4px;
		overflow: hidden;
		margin: 0 0 rem(16);
		padding: rem(20);
		border: 0;
		display: flex;
		align-items: stretch;
		justify-content: space-between;

		@include breakpoint($medium) {
			margin: 0 0 rem(20);
			padding: rem(32) rem(44);
		}
	}
	&__savings-block {
		align-items: center;
		color: $mine-shaft;
		display: flex;
		flex-direction: column;
		padding: 0 rem(20);
		width: 50%;
		text-align: center;

		@include breakpoint($medium) {
			padding: rem(8) rem(48);
		}
		&:first-child {
			border-right: 2px solid rgba($input-border, 0.2);
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
	&__savings-amount {
		font-size: rem(50);
		font-weight: 700;
		height: rem(40);
		line-height: 1;
		letter-spacing: -1px;
		margin-bottom: rem(6);

		@include breakpoint($medium) {
			margin-bottom: rem(8);
		}

		&--currency {
			font-size: rem(24);
			letter-spacing: 0;
			top: rem(-16);
		}
	}
	&__savings-eyebrow {
		color: $xfinity-black;
		font-size: rem(14);
		letter-spacing: 0.25px;
		line-height: 1.33;
		margin-bottom: rem(6);

		@include breakpoint($medium) {
			margin-bottom: rem(8);
		}
	}
	&__savings-time {
		font-size: rem(16);
		font-weight: 400;
		line-height: 1.25;

		@include breakpoint($medium) {
			font-size: rem(18);
		}
	}
	&__savings-plan {
		font-size: rem(10);
		color: $tundora;
		@include breakpoint($medium) {
			font-size: rem(12);
		}
	}
	&__savings-promo {
		background: #ececf3;
		margin-top: rem(-1); // hide hr above

		&-inner {
			margin: auto;
			max-width: rem(740);

			@include breakpoint($medium) {
				display: flex;
			}
		}

		&-content {
			padding: rem(45) rem(15);

			@include breakpoint($medium) {
				align-self: center;
				flex: 1;
			}
		}

		&-media {
			margin: rem(-20) auto 0;

			@include breakpoint($medium) {
				margin: 0 rem(-14) 0 0 ;
				max-width: none;
				width: rem(750);
				flex: 1;
				align-self: flex-end;
			}

			img {
				display: block;
				margin: 0 auto;
				max-width: 100%;
			}
		}

		&-buttons {

			a {
				margin-right: rem(25);

				&:last-child {
					margin: 0;
				}
			}
		}

		&-eyebrow {
			font-size: rem(14);
			line-height: rem(20);
			margin-bottom: rem(10);
			font-weight: 6 00;
			text-transform: uppercase;
		}

		&-headline {
			font-size: rem(22);
			line-height: rem(32);
			margin-bottom: rem(20);
			font-weight: 700;
			letter-spacing: -1px;

			@include breakpoint($medium) {
				max-width: rem(320);
			}
		}
	}
	&__recommended-plan {
		background: #ececf3;
		border-radius: 4px;
		overflow: hidden;
		margin: 0;
		padding: rem(32) rem(20);
		border: 0;
		@include breakpoint($medium) {
			padding: rem(32) rem(46);
		}
	}
	&__recommended-headline {
		font-weight: 700;
		font-size: rem(26);
		line-height: 1.31;
		margin: 0 0 rem(24);
		@include breakpoint($medium) {
			text-align: center;
			font-size: rem(32);
			line-height: 1.25;
			margin: 0 0 rem(32);
		}
	}
	&__plan-block {
		display: block;
		width: 100%;
		@include breakpoint($medium) {
			max-width: none;
			margin: auto;
		}
	}
	&__plan-breakdown {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 0;
		font-size: rem(16);
		margin: 0 0 rem(14);

		@include breakpoint($medium) {
			margin-bottom: rem(20);
		}
	}
	&__plan-name {
		color: $mine-shaft;
		font-size: rem(16);
		line-height: rem(24);
		font-weight: 400;
	}
	&__plan-price {
		color: $mine-shaft;
		font-size: rem(18);
		font-weight: 700;
		line-height: rem(22);
		&--currency {
			font-size: rem(14);
			left: -2px;
			line-height: rem(17);
			top: -3px;
			font-weight: 400;
			@include breakpoint($medium) {
				top: rem(-2);
			}
		}
	}
	&__plan-type {
		font-weight: 700;
	}
	&__total-block {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: rem(14) 0 rem(8);
		margin: 0 0 rem(12);
		border: solid $border-grey;
		border-width: 2px 0;
		border-color: rgba($input-border, 0.2);
		@include breakpoint($medium) {
			max-width: none;
			margin: rem(20) 0;
			padding: rem(20) 0;
		}
	}
	&__total-title {
		color: $mine-shaft;
		font-size: rem(16);
		line-height: rem(24);
		font-weight: 500;
	}
	&__total-price-wrap {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		flex-direction: column;
	}
	&__total-price {
		color: $mine-shaft;
		display: block;
		font-size: rem(18);
		font-weight: 700;
		line-height: 1.22;
		text-align: right;
		width: 100%;
		&--currency {
			font-size: rem(14);
			font-weight: 400;
			left: -2px;
			line-height: rem(17);
			top: -3px;
		}
	}

	&__disclaimer-reset-block {
		padding: rem(14) 0 0;
		@include breakpoint($medium) {
			padding: rem(20) 0 0;
		}
		p {
			text-align: center;
		}
	}

	&__price-disclaimer {
		font-size: rem(10);
		color: $xfinity-footnote;
		display: inline-flex;
		max-width: none;
		text-align: right;
		line-height: 1.3;
		margin-top: rem(4);
	}
	&__total-disclaimer {
		font-size: rem(10);
		color: $xfinity-footnote;
		text-align: left;
		font-weight: 400;
		line-height: rem(13);
		margin: 0;
		@include breakpoint($medium) {
			max-width: none;
			margin: auto;
		}
		sup {
			top: 0;
		}
		a {
			background: none;
			color: $xfinity-footnote;
			font-size: rem(12);

			@include breakpoint($medium) {
				font-size: rem(12);
			}
		}
	}
	&__show-alt-wrap {
		margin: 0 rem(15) rem(30);
		@include breakpoint($medium) {
			margin: 0 auto rem(46);
		}
	}
	&__show-alt {
		background-color: transparent;
		border-radius: 6px;
		padding: rem(70) rem(15);
		margin: auto;
		max-width: rem(1280);
	}

	&__alt-sub-headline {
		text-transform: none;
		font-size: rem(26);
		text-align: center;
		font-weight: 700;
		margin: 0;
		padding: 0;
		line-height: 1.31;
		letter-spacing: 0.25px;
		@include breakpoint($medium) {
			font-size: rem(36);
			letter-spacing: 0;
			line-height: 1.22;
			margin: 0 auto rem(8);
			max-width: 1000px;
		}
	}

	&__alt-headline {
		font-size: rem(18);
		line-height: rem(24);
		font-weight: 500;
		text-align: center;
		@include breakpoint($medium) {
			font-size: rem(20);
		}
	}
	&__help-headline {
		font-size: rem(26);
		line-height: 1.31;
		font-weight: 700;
		padding: rem(40) 0 0;
		border-top: 1px solid $xfinity-border;
		letter-spacing: -2.16667px;
		margin: 0;
		text-align: center;
		@include breakpoint($medium) {
			font-size: rem(40);
			line-height: 1.25;
			padding: rem(46) 0 0;
		}
	}
	&__help-number {
		display: block;
		font-weight: 400;
		padding: rem(16) 0 rem(2);
		@include breakpoint($medium) {
			padding: rem(10) 0 0;
		}

		a {
			@extend .button;
			@extend .button__secondary;
		}
	}
	&__alt-flex {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		@include breakpoint($medium) {
			flex-direction: row;
			align-items: flex-start;
		}
	}
	&__alt-row {
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		width: 100%;
		padding: 0;
		margin: 0 0 rem(32);
		max-width: rem(400);

		&:last-child {
			margin-bottom: 0;
		}

		@include breakpoint($medium) {
			flex-direction: column;
			margin: 0 rem(16);

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
	&__alt-icon {
		margin: 0 0 rem(12);
		img {
			max-width: 100%;
			max-height: 100%;
			height: auto;
			width: auto;
		}
		@include breakpoint($medium) {
			min-height: 70px;
			margin: 0 0 rem(20);
		}
	}
	&__alt-content {
		text-align: center;
		font-size: rem(14);
		line-height: 1.4;
		color: $disclaimer-grey;

		h5 {
			font-size: rem(20);
			font-weight: 400;
			margin: 0 0 rem(20);
			color: $black;
		}

		@include breakpoint($medium) {
			font-size: rem(16);

			h5 {
				font-size: rem(24);
			}
		}
	}

	.flex-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__brands-savings {
		margin: rem(30) 0 0;
		@include breakpoint($medium) {
			margin: rem(30) 0 rem(45);
		}
	}

	&__brands-heading {
		font-size: 1rem;
		margin: 0 0 rem(20);
	}

	&__brands-container {
		flex-wrap: nowrap;
		justify-content: center;
		margin: 0 0 rem(20);
	}

	&__brand-comparison {
		border-right:rgba(107, 113, 118, 0.3) solid 1px;
		padding: rem(16) rem(12) 0;
		width: 140px;
		&:last-of-type {
			border: none;
		}
		&--company {
			font-size: 0.8125rem;
			font-weight: 700;
			letter-spacing: 1px;
			line-height: 1rem;
			margin: 0;
			text-transform: uppercase;
			white-space: nowrap;
		}
		&--price {
			font-size: 2rem;
			font-weight: 500;
			margin-bottom: 0;
			sup {
				font-size: 50%;
				margin-right: 2px;
				top: -0.667em;
			}
		}
		&--caption {
			font-size: 0.75rem;
		}
	}

	&__brands-subheading {

		p {
			color: $disclaimer-grey;
			font-size: 0.6667rem;
			font-weight: 700;

			&:last-child {
				margin: 0;
			}
		}
	}

	&__disclaimer {
		font-weight: 300;
		color: $black;
		text-align: center;
		max-width: rem(560);
		margin: 0 auto rem(96);
		padding: 0 rem(32);
		font-family: XfinityBrownWeb;
		white-space: pre-line;

		p, a {
			font-size: rem(12);
			line-height: rem(16);
		}

		@include breakpoint($medium) {
			padding: 0;
		}
	}
	
	

	&__single-callout {
		padding: rem(16) rem(30);
		background: $white-haze;
		margin: rem(-30) 0 rem(30);

		h3 {
			font-weight: 700;
			font-size: rem(18);
			letter-spacing: -1px;
			margin: 0 0 rem(6);
		}

		p {
			font-weight: 400;
			font-size: rem(12);
			line-height: 125%;
		}

		img {
			width: rem(78);
			margin-right: rem(16);
		}

		&-inner {
			max-width: rem(760);
			margin: 0 auto;
			display: flex;
			align-items: flex-start;
		}

		@include breakpoint($small) {
			margin-bottom: rem(50);

			h3 {
				font-size: rem(20);
			}

			p {
				font-size: rem(14);
			}

			img {
				width: rem(100);
				margin-right: rem(40);
			}

			&-inner {
				align-items: center;
			}
		}

		@include breakpoint($medium) {
			margin-top: rem(-50);
		}
	}

	&__triple-callout {
		padding: rem(24) rem(30);
		background: $white-haze;
		margin: rem(-30) 0 rem(30);

		h3 {
			font-weight: 700;
			font-size: rem(18);
			letter-spacing: 0.25px;
			margin: 0 0 rem(20);
		}

		&-inner {
			max-width: rem(1160);
			margin: 0 auto;
		}

		&-item {
			display: flex;
			align-items: flex-start;
			margin-bottom: rem(16);

			&:last-child {
				margin-bottom: 0;
			}

			img {
				width: rem(24);
				margin-right: rem(16);
			}

			p {
				margin-bottom: 0;
				font-size: rem(14);
				line-height: 125%;
				font-weight: 400;
			}
		}

		@include breakpoint($medium) {
			margin-top: rem(-50);

			&-items {
				display: flex;
				margin: 0 rem(-20);
			}

			&-item {
				margin: 0;
				padding: 0 rem(20);
				flex-grow: 1;
				flex-basis: 0;
			}
		}
	}
}

