html {
	box-sizing: border-box;
	height: 100%;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	flex-direction: column;
	margin: 0;
	font-size: rem(14);
	line-height: 1.5;
	font-family: XfinityBrownWeb, Helvetica, Arial, sans-serif;
	font-weight: 400;
	color: $xfinity-black;
	text-transform: none;
	letter-spacing: 0;
	background-color: $white;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

.app {
	height: 100vh;
}

#root {
	min-height: 100vh;
}

.app-wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

main {
	flex-grow: 1;
}

header,
main,
footer {
	flex-shrink: 0;
}
